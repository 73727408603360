import Gallery from "../component/Gal";
import TestiMonials from "../component/Testimonial";

import Footer from "../component/footer";

export default function Gal(){
    return (
        <>
         <Gallery/> 
         <TestiMonials/>
         <Footer/>
        </>
    );
}