import Contact from "../component/contact";
import Footer from "../component/footer";

function ContactUs() {
    return (
        <div>
            <Contact/>
           <Footer/>
        </div>
    );
}

export default ContactUs;
