
import Footer from "../component/footer";
import TariffPackages from "../component/rooms";
import RecipeReviewCard from '../component/romcard';

export default function Room(){
    return (
        <>
        
           <TariffPackages/>
           <RecipeReviewCard/>
           <Footer/>
          
        </>
    );
}